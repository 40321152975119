import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = (props) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contactJson {
        phone
        email
        contact_button_link
      }
    }
  `);
  return (
    <div className='call'>
      <div className='call-box-top'>
        <div className='call-phone'>
          <strong>Phone: </strong> <a href='tel:(315) 422-1305'>(315) 422-1305 </a>
        </div>
        <div className='call-phone'>
          <strong>Fax: </strong> (315) 422-3133{' '}
        </div>
      </div>
      {props.showButton && (
        <div className='call-box-bottom'>
          <a href={data.contactJson.contact_button_link} className='button'>
            Map and Directions
          </a>
        </div>
      )}
    </div>
  );
};

export default Call;
